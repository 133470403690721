export async function uploadFileToS3(presignedUrl: string, file: Blob, onFileProgressChanged: (percentage: number) => void) {
	return makeRequest(presignedUrl, file, onFileProgressChanged);
}

function makeRequest(url: string, file: Blob, onFileProgressChanged: (percentage: number) => void) {
	return new Promise(function (resolve, reject) {
		let xhr = new XMLHttpRequest();
		xhr.open("PUT", url);
		xhr.onload = function () {
			if (this.status >= 200 && this.status < 300) {
				resolve(xhr.response);
			} else {
				reject({
					status: this.status,
					statusText: xhr.statusText,
				});
			}
		};
		xhr.onerror = function () {
			reject({
				status: this.status,
				statusText: xhr.statusText,
			});
		};
		xhr.upload.addEventListener("progress", function (e) {
			const percentage = (e.loaded / e.total) * 100;
			const percentageAsInt = parseInt(percentage.toFixed(0));
			onFileProgressChanged(percentageAsInt);
		});
		xhr.send(file);
	});
}
