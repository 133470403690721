import {action, flow, makeObservable, observable} from "mobx";
import {IApiClient} from "../common/api.client";
import {DlpEventCarouselResponse, DlpEventDto, DlpKnowledgeHubCarouselResponse, DlpKnowledgeHubDto} from "../common/webapicall";
import {getNotEmptyStringOrNull} from "../utils/string-utils";
import {LoadingState} from "../types/LoadingState";

export class DashboardCarouselsStore {
	@observable loadingState: LoadingState;

	@observable events: DlpEventDto[] = [];
	@observable selectedEventRegionId: string | null = null;
	@observable selectedEventTopicId: string | null = null;
	@observable selectedEventTypeId: string | null = null;

	@observable knowledgeItems: DlpKnowledgeHubDto[] = [];
	@observable totalKnowledgeItems: number = 0;

	@observable selectedKnowledgeContentTypeId: string | null = null;
	@observable selectedKnowledgeTopicIds: string[] | null = null;

	constructor(private apiClient: IApiClient) {
		makeObservable(this);

		this.loadingState = "initial";
	}

	@flow
	*init() {
		this.loadingState = "loading";

		yield this.initEvents();

		this.loadingState = "completed";
	}

	@flow
	*initEvents(skip: number = 0, take: number = 25, getAllItems: boolean = false) {
		const eventResponse: DlpEventCarouselResponse = yield this.fetchEvents(skip, take, getAllItems);
		this.events = [...eventResponse.events];
	}

	@flow
	*updateEvents(skip: number = 0, take: number = 25, getAllitems: boolean = true) {
		const eventResponse: DlpEventCarouselResponse = yield this.fetchEvents(skip, take, getAllitems);
		this.events = [...this.events, ...eventResponse.events];
	}

	@flow
	*initKnowledgeItems(skip: number = 0, take: number = 25, getAllItems: boolean = false) {
		const knowledgeResponse: DlpKnowledgeHubCarouselResponse = yield this.fetchKnowledgeItems(skip, take, getAllItems);

		this.knowledgeItems = [...knowledgeResponse.items];
		this.totalKnowledgeItems = knowledgeResponse.totalCount;
	}

	@flow
	*updateKnowledgeItems(skip: number = 0, take: number = 25, getAllItems: boolean = false) {
		const knowledgeResponse: DlpKnowledgeHubCarouselResponse = yield this.fetchKnowledgeItems(skip, take, getAllItems);

		this.knowledgeItems = [...this.knowledgeItems, ...knowledgeResponse.items];
		this.totalKnowledgeItems = knowledgeResponse.totalCount;
	}

	@flow
	*setSelectedEventRegionId(region: string | null) {
		this.selectedEventRegionId = getNotEmptyStringOrNull(region);
		yield this.setFilteredEvents();
	}

	@flow
	*setSelectedEventTopicId(topic: string | null) {
		this.selectedEventTopicId = getNotEmptyStringOrNull(topic);
		yield this.setFilteredEvents();
	}

	@flow
	*setSelectedEventTypeId(typeId: string | null) {
		this.selectedEventTypeId = getNotEmptyStringOrNull(typeId);
		yield this.setFilteredEvents();
	}

	@flow
	*setFilteredEvents() {
		yield this.initEvents();
	}

	@flow
	*setSelectedKnowledgeContentTypeId(contentTypeId: string | null) {
		this.selectedKnowledgeContentTypeId = getNotEmptyStringOrNull(contentTypeId);
		yield this.initKnowledgeItems();
	}

	@flow
	*setSelectedKnowledgeTopicIds(topicIds: string[] | null) {
		this.selectedKnowledgeTopicIds = topicIds;
		yield this.initKnowledgeItems();
	}

	@action
	emptyKnowledgeItems() {
		this.selectedKnowledgeTopicIds = null;
		this.knowledgeItems = [];
		this.totalKnowledgeItems = 0;
	}

	private fetchEvents(skip: number = 0, take: number = 25, getAllItems: boolean = false) {
		return this.apiClient.dlpContentClient.getAllEvents(
			this.selectedEventRegionId,
			this.selectedEventTopicId,
			this.selectedEventTypeId,
			skip,
			take,
			getAllItems,
		);
	}

	private fetchKnowledgeItems(skip: number = 0, take: number = 25, getAllItems: boolean = false) {
		return this.apiClient.dlpContentClient.getAllKnowledges(this.selectedKnowledgeTopicIds, this.selectedKnowledgeContentTypeId, skip, take, getAllItems);
	}
}
