export function splitFilename(filename: string) {
	const lastDotIndex = filename.lastIndexOf(".");

	if (lastDotIndex === -1 || lastDotIndex === filename.length - 1) {
		return [filename, null];
	}

	const name = filename.substring(0, lastDotIndex);
	const extension = filename.substring(lastDotIndex + 1);

	return [name, extension];
}
