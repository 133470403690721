/* eslint-disable jsx-a11y/anchor-is-valid */
import {useAppStore} from "../../common/app.store.consumer";
import {DebugPanelOperation} from "../../stores/debug.panel.store";
import {withRouter} from "react-router";
import {ActionButton} from "../../common/action.button";
import {observer} from "mobx-react";
import {CpdType} from "../../common/webapicall";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserDropdown} from "../../user-dropdown/user.dropdown";

export const DebugPanelWithoutWrapper = observer(() => {
	const {debugPanelStore: store, configuration, userStore, authStore, dlpDashboardStore: dashboardStore} = useAppStore();

	async function logout() {
		await userStore.logout();
	}

	const isEnabled = configuration.isDebugPanelEnabled;
	const isVisible = store.debugPanelVisible;

	if (!isEnabled) {
		return null;
	}

	return (
		<>
			<div className="test_panel_toggle" onClick={store.toggleTestingPanel}>
				DEMO
			</div>
			{isVisible && (
				<div className="test_panel_wrap">
					<div className="pos-rel">
						<div className="test_panel">
							<div className="tp_tabs">
								<a style={{cursor: "pointer"}}>Actions</a>
								<a className="btn-link  tab-close" onClick={store.toggleTestingPanel}>
									<FontAwesomeIcon icon={["fas", "times"]} />
								</a>
							</div>
							<div>
								<div className="vspace10" />
								<div className="tp-title">User actions</div>
								<UserDropdown />
								{userStore.isLoggedIn && (
									<label className="tp_label">
										Logout
										<ActionButton onClick={logout} operation={DebugPanelOperation.Logout} />
									</label>
								)}
							</div>
							<div>
								<div className="vspace10" />
								<div className="tp-title">Role flags</div>
								<RoleFlagRow
									flag={authStore.hasMemberAccess}
									label="DLP Member Access"
									toggleAction={() => store.setHasMemberAccess(!authStore.hasMemberAccess)}
								/>
								<RoleFlagRow
									flag={authStore.isShowMembershipExpiringWarningEnabled}
									label="Show Membership Expiring Warning"
									toggleAction={() => store.setShowMembershipExpiringWarningAccess(!authStore.isShowMembershipExpiringWarningEnabled)}
								/>

								<label className="tp_label">
									CPD Type
									<select
										className="tmp-element"
										onChange={e => store.setCpdType(e.target.value)}
										defaultValue={userStore.memberInfo?.cpdType}
										style={{maxWidth: "200px"}}
									>
										{Object.keys(CpdType).map(t => (
											<option value={t} key={t}>
												{t}
											</option>
										))}
									</select>
								</label>
							</div>
							<div>
								<div className="vspace10" />
								<div className="tp-title">Dashboard details</div>
								<label className="tp_label">
									<FontAwesomeIcon
										icon={["fas", dashboardStore.hasAchievements ? "check" : "minus"]}
										className={dashboardStore.hasAchievements ? "ink-green" : "ink-mgrey"}
									/>
									Toggle achivements
									<ActionButton
										onClick={() => store.toggleDashboardAchivements()}
										operation={DebugPanelOperation.ToggleDashboardAchievementsPreview}
									/>
								</label>
								<label className="tp_label">
									<FontAwesomeIcon
										icon={["fas", dashboardStore.hasInProgressCourses ? "check" : "minus"]}
										className={dashboardStore.hasInProgressCourses ? "ink-green" : "ink-mgrey"}
									/>
									Toggle in progress courses
									<ActionButton
										onClick={() => store.toggleDashboardInProgressCourses()}
										operation={DebugPanelOperation.ToggleDashboardInProgressCourses}
									/>
								</label>
								<label className="tp_label">
									<FontAwesomeIcon
										icon={["fas", userStore.cpdProgress.hasCpdProgress ? "check" : "minus"]}
										className={userStore.cpdProgress.hasCpdProgress ? "ink-green" : "ink-mgrey"}
									/>
									Toggle CPD progress
									<ActionButton onClick={() => store.toggleCpdProgress()} operation={DebugPanelOperation.ToggleDashboardCpdProgress} />
								</label>
								<label className="tp_label">
									<FontAwesomeIcon
										icon={["fas", dashboardStore.hasPromotionalEvent ? "check" : "minus"]}
										className={dashboardStore.hasPromotionalEvent ? "ink-green" : "ink-mgrey"}
									/>
									Toggle promotional event
									<ActionButton
										onClick={() => store.toggleDashboardPromotionalEvent()}
										operation={DebugPanelOperation.ToggleDashboardPromotionalEvent}
									/>
								</label>
							</div>
							<div>
								<div className="vspace10" />
								<div className="tp-title">Achievements page</div>
								<label className="tp_label">
									<FontAwesomeIcon
										icon={store.isAchievementsPageEmptied ? "check" : "minus"}
										className={store.isAchievementsPageEmptied ? "ink-green" : "ink-mgrey"}
									/>
									Simulate no achievements
									<ActionButton onClick={() => store.toggleAchievements()} operation={DebugPanelOperation.ToggleAchievements} />
								</label>
							</div>
							<div style={{textAlign: "center", width: "100%"}}>
								<button style={{margin: "1.5em auto", width: "auto"}} onClick={store.toggleTestingPanel}>
									Close demo helper
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
});

const RoleFlagRow = observer(({flag, label, toggleAction}: {flag: boolean; label: string; toggleAction(): void}) => {
	return (
		<label className="tp_label">
			<FontAwesomeIcon icon={["fas", flag ? "check" : "minus"]} className={flag ? "ink-green" : "ink-mgrey"} /> {label}
			<ActionButton onClick={toggleAction} operation={DebugPanelOperation.ToggleRoleFlags} />
		</label>
	);
});

export const PublicDebugPanel = withRouter(DebugPanelWithoutWrapper);
