import {IApiClient} from "../common/api.client";
import {ContextModel} from "../common/webapicall";
import {action, makeObservable, observable} from "mobx";

export class AppContextStore {
	@observable contextModel: ContextModel;

	constructor(private apiClient: IApiClient) {
		makeObservable(this);
	}

	async loadContext(userId: string): Promise<void> {
		let contextResponse = await this.apiClient.contextClient.getContext({
			userId: userId,
		});
		this.handleContextResponse(contextResponse);
	}

	@action
	private handleContextResponse(contextResponse: ContextModel): void {
		this.contextModel = contextResponse;
	}
}
