import {observer} from "mobx-react";
import style from "./top.header.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAppStore} from "./app.store.consumer";
import {useState} from "react";
import {Link} from "react-router-dom";
import {RouteConstants} from "../resources/route-constants";

export const TopHeaderMobile = observer(() => {
	const appStore = useAppStore();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className={style.mobileMenu}>
			<div className={style.hamburgerIcon} onClick={() => setIsOpen(!isOpen)}>
				<FontAwesomeIcon icon={["fas", isOpen ? "times" : "bars"]} />
			</div>
			{isOpen && (
				<nav>
					<div className={style.mobileMenuContainer}>
						<div className={style.menuItem}>
							<Link to={RouteConstants.dlp} onClick={() => setIsOpen(!isOpen)}>
								Home
							</Link>
						</div>
						<div className={style.menuItem}>
							<Link to={RouteConstants.dlpCoursesAll} onClick={() => setIsOpen(!isOpen)}>
								Course Catalogue
							</Link>
						</div>
						<div className={style.menuItem}>
							<Link to={RouteConstants.qualification} onClick={() => setIsOpen(!isOpen)}>
								Qualifications
							</Link>
						</div>
						{appStore.userStore.isLoggedIn && (
							<div className={style.menuItem}>
								<Link to={RouteConstants.dlpAchievements} onClick={() => setIsOpen(!isOpen)}>
									My Learning
								</Link>
							</div>
						)}
						{appStore.authStore.hasCpdReadAccess && (
							<div className={style.menuItem}>
								<Link to={RouteConstants.cpdDashboard} onClick={() => setIsOpen(!isOpen)}>
									My CPD
								</Link>
							</div>
						)}
					</div>
				</nav>
			)}
		</div>
	);
});
