import * as crosstab from "crosstab";
import {AuthConfigImpl} from "../common/auth.config";
import {IApiClient} from "../common/api.client";
import {UserStore} from "./user.store";
import {AppContextStore} from "./app.context.store";
import {
	UserClient,
	ReportClient,
	LookupClient,
	ContextClient,
	DevelopmentGoalClient,
	SwotClient,
	CourseClient,
	CpdActivityClient,
	CpdActivityFileClient,
	CpdDashboardClient,
	DlpContentClient,
	BadgeClient,
	DlpDashboardClient,
	EnrollmentClient,
	QualificationClient,
} from "../common/webapicall";
import {IConfigurationProvider, AppPublicConfiguration} from "../common/public.configuration";
import {DebugPanelStore} from "./debug.panel.store";
import {makeObservable, observable} from "mobx";
import {DlpDashboardStore} from "./dlp.dashboard.store";
import {AuthStore} from "./authStore";
import {CourseCatalogStore} from "./course.catalog.store";
import {CourseDetailsStore} from "./course.details.store";
import {AchievementsStore} from "./achievements.store";
import {SwotStore} from "./swot.store";
import {CpdGoalStore} from "./cpd.goal.store";
import {CpdDevelopmentPlanDashboardStore} from "./cpd.development.plan.dashboard.store";
import {CpdSaveActivityStore} from "./cpd.save.activity.store";
import {LookupStore} from "./lookup.store";
import {CpdDashboardStore} from "./cpd.dashboard.store";
import {CourseCarouselStore} from "./course.carousel.store";
import {CourseEnrollmentStore} from "./course.enrollment.store";
import {CourseAutocompleteStore} from "./course.autocomplete.store";
import {SearchStore} from "./search.store";
import {QualificationStore} from "./qualification.store";

export interface IAppStore extends IConfigurationProvider {
	init(): void;
}

const RESIZE_OBSERVER_ERROR_MESSAGE = "ResizeObserver loop limit exceeded";

abstract class AppStoreBase implements IAppStore {
	abstract userStore: UserStore;
	abstract configuration: AppPublicConfiguration;
	abstract outDatedClient: boolean;
	abstract errorVisible: boolean;
	abstract errorMessage: string;

	abstract init(): void;

	protected subscribeEventHandlers() {
		window.addEventListener("error", async ev => {
			if (ev && ev.message === RESIZE_OBSERVER_ERROR_MESSAGE) {
				return;
			}
			if (typeof ev.error.stack === "string" && ev.error.stack.indexOf("crosstab.js")) {
				//console.log("supress crosstab.js errors.");
				return true;
			}

			await this.reportError(ev.error.stack);
		});
		window.onunhandledrejection = async ev => await this.reportUnhandledRejection(ev.reason);
	}

	protected setupCrosstab(tokenToCheck: string) {
		if (crosstab.supported) {
			let ctEventName: string = "crossTabOpenNewTab";
			crosstab.on(ctEventName, (message: any) => {
				if (crosstab.id === message.origin) {
					//console.log("Crosstab receiving event on the same tab, skip it", ct.id, JSON.stringify(message));
				} else {
					//console.log("Crosstab receiving event on a different tab, process it", ct.id, JSON.stringify(message));
					if (tokenToCheck !== message.data) {
						window.location.assign("#/forced-logout");
						return;
					}
				}
			});
			//console.log("Crosstab broadcast event from source tab", ct.id);
			crosstab.broadcast(ctEventName, tokenToCheck);
		}
	}

	private async reportUnhandledRejection(reason: any) {
		if (reason.isWebApiErrorResponse) {
			if (reason.statusCode === 406) {
				this.outDatedClient = true;
			} else if (reason.statusCode === 401) {
				if (this.userStore.isLoggedIn) {
					window.location.href = "#/session-expired";
					await this.userStore.logout();
				} else {
					window.location.href = this.userStore.getLoginUrl();
				}
			} else {
				await this.reportError(`Unhandled web api error:
					URL: ${reason.url} (${reason.statusCode})

					Body: ${reason.body}

					Stack: ${reason.stack}`);
			}
		} else {
			await this.reportError(`Unhandled rejection: ${reason}`);
		}
	}

	private async reportError(reason: any) {
		try {
			this.errorVisible = true;
			if (this.configuration.showErrorDetails) {
				this.errorMessage = JSON.stringify(reason);
			}

			if (reason && typeof reason === "string" && reason.includes("Object Not Found Matching Id")) {
				console.error(reason);
				return;
			}

			await this.sendClientException(reason);
		} catch (e) {
			// Just log it console as reporting it would be recursive.
			console.log("Error occurred during reporting JS error");
			console.log(e);
		}
	}

	protected abstract sendClientException(reason: any): Promise<void>;
}

export class AppStore extends AppStoreBase implements IConfigurationProvider {
	private readonly authConfig: AuthConfigImpl;

	apiClient: IApiClient;

	readonly userStore: UserStore;
	readonly authStore: AuthStore;
	readonly contextStore: AppContextStore;
	readonly debugPanelStore: DebugPanelStore;
	readonly dlpDashboardStore: DlpDashboardStore;
	readonly courseCatalogStore: CourseCatalogStore;
	readonly courseDetailsStore: CourseDetailsStore;
	readonly achievementsStore: AchievementsStore;
	readonly swotStore: SwotStore;
	readonly cpdGoalStore: CpdGoalStore;
	readonly cpdDevelopmentPlanDashboardStore: CpdDevelopmentPlanDashboardStore;
	readonly cpdSaveActivityStore: CpdSaveActivityStore;
	readonly lookupStore: LookupStore;
	readonly cpdDashboardStore: CpdDashboardStore;
	readonly courseCarouselStore: CourseCarouselStore;
	readonly courseEnrollmentStore: CourseEnrollmentStore;
	readonly courseAutocompleteStore: CourseAutocompleteStore;
	readonly searchStore: SearchStore;
	readonly qualificationStore: QualificationStore;

	configuration: AppPublicConfiguration;

	@observable outDatedClient: boolean = false;
	@observable errorVisible: boolean = false;
	@observable errorMessage: string = null;

	constructor() {
		super();
		makeObservable(this);

		this.authConfig = new AuthConfigImpl(this);

		this.apiClient = {
			setAuthToken: (newToken: string, refreshToken: string) => {
				this.authConfig.token = newToken;
				this.authConfig.refreshToken = refreshToken;
			},
			contextClient: new ContextClient(this.authConfig, null, this.authConfig),
			lookupClient: new LookupClient(this.authConfig, null, this.authConfig),
			reportClient: new ReportClient(this.authConfig, null, this.authConfig),
			userClient: new UserClient(this.authConfig, null, this.authConfig),
			developmentGoalClient: new DevelopmentGoalClient(this.authConfig, null, this.authConfig),
			swotClient: new SwotClient(this.authConfig, null, this.authConfig),
			courseClient: new CourseClient(this.authConfig, null, this.authConfig),
			cpdActivityClient: new CpdActivityClient(this.authConfig, null, this.authConfig),
			cpdActivityFileClient: new CpdActivityFileClient(this.authConfig, null, this.authConfig),
			cpdDashboardClient: new CpdDashboardClient(this.authConfig, null, this.authConfig),
			dlpContentClient: new DlpContentClient(this.authConfig, null, this.authConfig),
			badgeClient: new BadgeClient(this.authConfig, null, this.authConfig),
			dlpDashboardClient: new DlpDashboardClient(this.authConfig, null, this.authConfig),
			enrollmentClient: new EnrollmentClient(this.authConfig, null, this.authConfig),
			qualificationClient: new QualificationClient(this.authConfig, null, this.authConfig),
		};

		this.userStore = new UserStore(this.apiClient, this);
		this.authStore = new AuthStore(this.userStore);
		this.contextStore = new AppContextStore(this.apiClient);
		this.dlpDashboardStore = new DlpDashboardStore(this.apiClient);
		this.courseCatalogStore = new CourseCatalogStore(this.apiClient);
		this.courseDetailsStore = new CourseDetailsStore(this.apiClient, this);
		this.achievementsStore = new AchievementsStore(this.apiClient);
		this.lookupStore = new LookupStore(this.apiClient.lookupClient, this.apiClient.courseClient);
		this.cpdGoalStore = new CpdGoalStore(this.apiClient.developmentGoalClient, this.lookupStore);
		this.cpdDevelopmentPlanDashboardStore = new CpdDevelopmentPlanDashboardStore(this.apiClient);
		this.swotStore = new SwotStore(this.apiClient);
		this.cpdSaveActivityStore = new CpdSaveActivityStore(this.apiClient, this.authStore);
		this.debugPanelStore = new DebugPanelStore(this.userStore, this.dlpDashboardStore, this.achievementsStore, this);
		this.courseCarouselStore = new CourseCarouselStore(this.apiClient.courseClient);
		this.cpdDashboardStore = new CpdDashboardStore(this.apiClient);
		this.courseEnrollmentStore = new CourseEnrollmentStore(this.apiClient);
		this.courseAutocompleteStore = new CourseAutocompleteStore(this.apiClient.courseClient);
		this.searchStore = new SearchStore(this.apiClient.courseClient, this.apiClient.lookupClient, this.apiClient.qualificationClient);
		this.qualificationStore = new QualificationStore(this.apiClient.qualificationClient);
	}

	async init() {
		await this.loadApiVersion();
		await this.subscribeEventHandlers();

		await this.loadPublicConfiguration();
		await this.userStore.init();

		if (this.userStore.userId) {
			await this.contextStore.loadContext(this.userStore.userId);
		}

		await this.setupCrosstab(this.userStore.userId ?? "anonymous");
	}

	async loadPublicConfiguration() {
		let config = await this.apiClient.lookupClient.getPublicConfiguration();
		this.configuration = {
			...config,
			loginUrl: "/#/login",
		};
	}

	protected async sendClientException(reason: any): Promise<void> {
		await this.apiClient.reportClient.sendClientException({
			message: reason,
			url: window.location.href,
		});
	}

	private async loadApiVersion() {
		(window as any).apiVersion = await this.apiClient.lookupClient.getApiVersion();
	}
}
