import {useState} from "react";
import {observer} from "mobx-react";
import {useAppStore} from "./app.store.consumer";
import {TextBox, Button} from "devextreme-react/text-box";
import {useHistory} from "react-router-dom";
import {RouteConstants} from "../resources/route-constants";

export const Search = observer(() => {
	const [searchTerm, setSearchTerm] = useState<string>("");
	const history = useHistory();

	const {searchStore: store} = useAppStore();

	const onSearch = () => {
		if (!searchTerm) {
			return;
		}

		store.setSearchTerm(searchTerm.toLowerCase());
		if (history.location.pathname != RouteConstants.search) {
			history.push(RouteConstants.search);
		}
		setSearchTerm("");
	};

	return (
		<TextBox
			className="ciht-w-100"
			placeholder="Search..."
			showClearButton={false}
			onEnterKey={e => onSearch()}
			value={searchTerm}
			onValueChanged={e => setSearchTerm(e.value)}
		>
			<Button name="search" location="after" options={{icon: "search", stylingMode: "text", onClick: () => onSearch()}} />
		</TextBox>
	);
});
