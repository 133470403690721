import {useState, useLayoutEffect} from "react";
import style from "./top.header.module.scss";
import logoImg from "../assets/ciht-logo.png";
import {observer} from "mobx-react";
import {useAppStore} from "./app.store.consumer";
import {Link} from "react-router-dom";
import {RouteConstants} from "../resources/route-constants";
import {LoginButton} from "./login.button";
import {Search} from "./search";
import {TopHeaderMobile} from "./top.header.mobile";
import {CourseAutoComplete} from "../dlp/CourseAutoComplete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function useScrollPosition() {
	const [scrollPosition, setPosition] = useState(0);

	useLayoutEffect(() => {
		function updatePosition() {
			setPosition(window.pageYOffset);
		}

		window.addEventListener("scroll", updatePosition);

		updatePosition();

		return () => window.removeEventListener("scroll", updatePosition);
	}, []);

	return scrollPosition;
}

export const TopHeader = observer(() => {
	const appStore = useAppStore();
	const scrollPosition = useScrollPosition();

	return (
		<div className={`${style.wrapper} ${scrollPosition !== 0 && style.shadow}`}>
			<header className={style.topHeader}>
				<div className={style.logo}>
					<img src={logoImg} alt="CIHT logo" />
				</div>

				<nav className={style.mainMenu}>
					<div className={style.menuContainer}>
						<div className={style.menuItem}>
							<Link to={RouteConstants.dlp}>Home</Link>
						</div>
						<div className={style.menuItem}>
							<Link to={RouteConstants.dlpCoursesAll}>Course Catalogue</Link>
						</div>
						<div className={style.menuItem}>
							<Link to={RouteConstants.qualification}>Qualifications</Link>
						</div>
						{appStore.userStore.isLoggedIn && (
							<div className={style.menuItem}>
								<Link to={RouteConstants.dlpAchievements}>My Learning</Link>
							</div>
						)}
						{appStore.authStore.hasCpdReadAccess && (
							<div className={style.menuItem}>
								<Link to={RouteConstants.cpdDashboard}>My CPD</Link>
							</div>
						)}
					</div>
				</nav>

				<div className={style.rightMenu}>
					<div className="ciht-top-search ciht-mr-15 ciht-flex-center ciht-flex-gap ciht-flex-wrap ciht-w-100">
						<Search />
					</div>
					<LoginButton />
					<TopHeaderMobile />
				</div>
			</header>
		</div>
	);
});
