import React, {useEffect, useRef} from "react";
import {ModalRenderer} from "./ModalRenderer";
import {useKeyboardNavigation as keyboardNavigation, focusElementByIdLast} from "wcag-tools";
import style from "./ConfirmationModal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

let lastModalId = 0;

export type ConfirmationModalProps = React.PropsWithChildren<{
	isVisible: boolean;
	onCancel: () => Promise<void> | void;
	onConfirm: () => Promise<void> | void;
	cancelLabel: string;
	confirmLabel: string;
	["aria-labelledby"]?: string;
	confirmActionType?: "danger" | "positive";
}>;

export const ConfirmationModal = ({
	isVisible,
	onConfirm,
	onCancel,
	cancelLabel,
	confirmLabel,
	children,
	confirmActionType = "danger",
	...rest
}: ConfirmationModalProps) => {
	const confirmButtonIdRef = useRef(
		(() => {
			lastModalId += 1;
			return `cm-confirm-btn-${lastModalId}`;
		})(),
	);

	useEffect(() => {
		if (isVisible) {
			// noinspection JSIgnoredPromiseFromCall
			focusElementByIdLast(confirmButtonIdRef.current);
		}
	}, [isVisible]);

	return (
		<ModalRenderer isVisible={isVisible} aria-labelledby={rest["aria-labelledby"]}>
				<div className="header">
					<FontAwesomeIcon
						className="tint-teal"
						icon={["fas", "times"]}
						onClick={onCancel}
						{...keyboardNavigation<any>({clickable: true, role: "button", label: "Close dialog"})}
					/>
				</div>
				{children}
				<div className="buttonRow">
					<button
						id={confirmButtonIdRef.current}
						className={`${confirmActionType === "positive" ? style.positive : "alert"}`}
						{...keyboardNavigation({clickable: true})}
						onClick={onConfirm}
					>
						{confirmLabel}
					</button>
					<button className={style.btn} {...keyboardNavigation({clickable: true})} onClick={onCancel}>
						{cancelLabel}
					</button>
				</div>
		</ModalRenderer>
	);
};
