import {IApiClient} from "../common/api.client";
import { PurchaseDto, PurchaseProcessStatus} from "../common/webapicall";

export class CourseEnrollmentStore {
	private readonly apiClient: IApiClient;

	constructor(apiClient: IApiClient) {
		// makeObservable(this);
		this.apiClient = apiClient;
	}

	async getStatus(crmProductId: string): Promise<PurchaseDto> {
		return await this.apiClient.enrollmentClient.getStatus(crmProductId);
	}

	async checkPurchaseStatus(courseId: string): Promise<PurchaseProcessStatus> {
		return await this.apiClient.enrollmentClient.checkPurchaseStatus(courseId);
	}

	async enroll(courseId: string): Promise<PurchaseProcessStatus> {
		return await this.apiClient.enrollmentClient.initiateEnrollment(courseId);
	}

	async sendConfirmation(courseId: string): Promise<PurchaseProcessStatus> {
		return await this.apiClient.enrollmentClient.initiateConfirmation(courseId);
	}

	async deleteFailedPurchase(courseId: string) {
		await this.apiClient.enrollmentClient.deleteFailed(courseId);
	}
}
