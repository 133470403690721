import {App} from "./App";
import * as serviceWorker from "./serviceWorker";
import {fontawesomeSetup} from "./fontawesome-setup";
import "./scss/main.scss";

(async () => {
	fontawesomeSetup();

	const app = new App();
	await app.start();
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
