import React from "react";
import {useAppStore} from "./app.store.consumer";
import {DebugPanelOperation} from "../stores/debug.panel.store";
import {observer} from "mobx-react";

export type ActionButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {operation: DebugPanelOperation; disabled?: boolean};

export const ActionButton = observer(({operation, ...props}: ActionButtonProps) => {
	const appStore = useAppStore();

	const store = appStore.debugPanelStore;
	const disabled = !!props.disabled || store.isLoading;

	return (
		<button {...props} disabled={disabled}>
			{operation === store.operation && disabled ? "..." : "GO"}
		</button>
	);
});
