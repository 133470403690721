import React from "react";
import ReactDOM from "react-dom";
import {AppStore} from "./stores/app.store";
import {MainFrame} from "./common/mainframe";
import "core-js/stable";

export class App {
	private readonly appStore: AppStore;

	constructor() {
		this.appStore = new AppStore();
		App.refreshTitle();
	}

	private static refreshTitle() {
		document.title = `The Chartered Institution of Highways & Transportation`;
	}

	async start() {
		await this.appStore.init().catch(reason => {
			// ignore to let React get initialized
			console.error(reason);
		});

		ReactDOM.render(<MainFrame appStore={this.appStore} />, document.getElementById("root"));
	}
}
