import React, {useEffect, useRef, useState} from "react";
import style from "./top.header.module.scss";
import {observer} from "mobx-react";
import {useAppStore} from "./app.store.consumer";
import {generatePath, matchPath, Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RouteConstants} from "../resources/route-constants";
import {CihtOrgRouteConstants} from "../resources/ciht-org-route-constants";
import {useKeyboardNavigation as keyboardNavigation} from "wcag-tools";

function useCloseOnClickOutside(isOpen: boolean, setIsOpen: (isOpen: boolean) => void) {
	const wrapperRef = useRef<HTMLElement>(null);

	useEffect(() => {
		function closeOnClick(e: MouseEvent) {
			if (!wrapperRef.current) {
				return;
			}

			const containerElement = wrapperRef.current;

			if (!(e.target instanceof Element)) {
				return;
			}

			if (!containerElement.contains(e.target)) {
				setIsOpen(false);
			}
		}

		if (isOpen) {
			window.addEventListener("click", closeOnClick);
		}

		return () => window.removeEventListener("click", closeOnClick);
	}, [setIsOpen, isOpen]);

	return wrapperRef;
}

export const LoginButton = observer(() => {
	const appStore = useAppStore();
	const {userStore} = appStore;
	const {pathname} = useLocation();

	const [isOpen, setIsOpen] = useState(false);

	const wrapperRef = useCloseOnClickOutside(isOpen, setIsOpen);

	function getLoginUrl() {
		const match = matchPath<{redirectUrl: "string"}>(pathname, {
			path: RouteConstants.loginWithRedirectUrl,
			exact: true,
			strict: false,
		});

		let redirectUrl = match && match.params.redirectUrl ? match.params.redirectUrl : "/#" + pathname;

		return generatePath(RouteConstants.loginWithRedirectUrl, {
			redirectUrl: redirectUrl,
		});
	}

	if (!userStore.isLoggedIn) {
		return (
			<Link to={getLoginUrl()} {...keyboardNavigation({clickable: true})}>
				<FontAwesomeIcon className={style.faIcon} transform="shrink-5" icon={["fas", "user"]} />
				<span>Login</span>
			</Link>
		);
	}

	return (
		<nav ref={wrapperRef}>
			<div className={style.loginMenuContainer}>
				<div className={style.menuItem}>
					<div>
						<span className={style.top}></span>
						{/* <span className={style.bottom}>
							MyCIHT
							<FontAwesomeIcon transform="down-2 right-4" icon={["fas", "chevron-down"]} />
						</span> */}
						<span className={`${style.bottom} ciht-flex-col`} onClick={() => userStore.logout()}>
							<FontAwesomeIcon className={style.faIcon} icon={["fas", "arrow-to-right"]} />
							<span>Logout</span>
						</span>
					</div>
					<div className="topmenu-modal noBackground">
						<div className={style.dropdown}>
							<div className={`${style.content} ${isOpen ? style.open : ""}`} style={{gridTemplateAreas: "dlp cpd"}}>
								<div className={style.category}>
									<span className="tint-black ciht-mb-05">{userStore.userName}</span>
									<a href={CihtOrgRouteConstants.getMyAccount(appStore.configuration.cihtSiteUrl)}>
										<FontAwesomeIcon className={style.faIcon} transform="shrink-5 up-4" icon={["fas", "user"]} />
										<span>MY Account</span>
									</a>
									<button className="linkALike tint-dblue" onClick={() => userStore.logout()}>
										<FontAwesomeIcon className={style.faIcon} transform="shrink-5 down-2" icon={["fas", "arrow-to-right"]} />
										Logout
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
});
