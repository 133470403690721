import {computed, makeObservable, observable} from "mobx";
import {CpdType, MembershipStatus} from "../common/webapicall";
import {UserStore} from "./user.store";

export class AuthStore {
	@observable roles: Set<string>;

	constructor(private userStore: UserStore) {
		makeObservable(this);
	}

	@computed
	get hasMemberAccess(): boolean | undefined {
		return this.userStore.memberInfo?.hasDlpMemberAccess;
	}

	@computed
	get isRegisteredNonMember(): boolean {
		return this.userStore.isLoggedIn && !this.userStore.memberInfo?.hasDlpMemberAccess;
	}

	@computed
	get isCPDAllowed(): boolean {
		return this.userStore.memberInfo?.cpdType === CpdType.Allowed;
	}

	@computed
	get isCPDRequired(): boolean {
		return this.userStore.memberInfo?.cpdType === CpdType.Required;
	}

	@computed
	get hasCpdAccess(): boolean {
		return this.userStore.isLoggedIn && this.hasMemberAccess && this.userStore.memberInfo?.cpdType !== CpdType.NoAccess;
	}

	@computed
	get hasCpdReadAccess(): boolean {
		return this.userStore.isLoggedIn && (this.userStore?.cpdProgress.hasCpdProgress || this.hasCpdAccess);
	}

	@computed
	get isShowMembershipExpiringWarningEnabled() {
		return this.userStore.memberInfo?.showMembershipExpiringWarning;
	}

	@computed
	get membershipStatus(): MembershipStatus {
		if (!this.userStore.isLoggedIn) {
			return MembershipStatus.Anonymous;
		}

		if (this.userStore.memberInfo?.showMembershipExpiringWarning) {
			return MembershipStatus.Expired;
		}

		if (this.userStore.memberInfo?.hasDlpMemberAccess) {
			return MembershipStatus.Member;
		}

		return MembershipStatus.Public;
	}
}
