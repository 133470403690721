import React from "react";
import {HashRouter} from "react-router-dom";
import {AppStore} from "../stores/app.store";
import {Routes} from "./top.level.routes";
import {MainBlank} from "./blank";
import {OutdatedClient} from "./outdated.client";
import {Error} from "./error.component";
import {AppContextProvider} from "./app.store.consumer";
import {PublicDebugPanel} from "../public/debug-panel/debug.panel";
import {TopHeader} from "./top.header";
import {TopFooter} from "./top.footer";

export const AppContext: React.Context<AppStore | null> = React.createContext<AppStore | null>(null);
AppContextProvider.appContext = AppContext;

export const MainFrame = (props: {appStore: AppStore}) => {
	return (
		<React.Suspense fallback={<MainBlank />}>
			<AppContext.Provider value={props.appStore}>
				<React.Fragment>
					<HashRouter>
						<PublicDebugPanel />
						<TopHeader />
						<React.Suspense fallback={<MainBlank />}>
							<Routes />
						</React.Suspense>
						<Error />
						<OutdatedClient />
						<TopFooter />
					</HashRouter>
				</React.Fragment>
			</AppContext.Provider>
		</React.Suspense>
	);
};
