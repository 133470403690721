import {action, computed, flow, makeObservable, observable} from "mobx";
import {CourseCategory, CourseTiles, GetCourseTilesResponse, OrderByType} from "../common/webapicall";
import {IApiClient} from "../common/api.client";
import debounce from "lodash/debounce";

export class CourseCatalogStore {
	private readonly apiClient: IApiClient;
	private readonly defaultPageSize: number = 12;

	@observable courses: CourseTiles[];
	@observable coursesLoading: boolean;
	@observable topicIdQueryParameter: string = "";
	@observable orderByQueryParameter: OrderByType = OrderByType.Newest;
	@observable categoryQueryParameter: CourseCategory | null = null;
	@observable skipQueryParameter: number = 0;
	@observable takeQueryParameter: number = this.defaultPageSize;
	@observable totalCount: number = 0;

	constructor(apiClient: IApiClient) {
		makeObservable(this);
		this.apiClient = apiClient;

		this.courses = [];
		this.coursesLoading = false;
	}

	@flow
	*init() {
		yield this.fetchCourseTiles(true);
	}

	@flow
	*fetchCourseTiles(getTotalCount: boolean) {
		this.coursesLoading = true;
		const response: GetCourseTilesResponse = yield this.apiClient.courseClient.getCourseTiles(
			this.topicIdQueryParameter,
			this.orderByQueryParameter,
			this.skipQueryParameter,
			this.takeQueryParameter,
			getTotalCount,
			this.categoryQueryParameter
		);

		if (getTotalCount) {
			this.courses = response.courseTiles;
			this.totalCount = response.totalCount;
		} else {
			this.courses = [...this.courses, ...response.courseTiles];
		}

		this.coursesLoading = false;
	}

	@action
	setOrderByQueryParameterValue(value: string) {
		this.orderByQueryParameter = Object.values(OrderByType).indexOf(value);
		this.skipQueryParameter = 0;
		this.fetchCourseTiles(true);
	}

	@action
	setTopicIdFilterQueryParameterValue(value: string) {
		this.topicIdQueryParameter = value;
		this.skipQueryParameter = 0;
		this.fetchCourseTiles(true);
	}
	
	@action
	setCategoryFilterQueryParameterValue(value: CourseCategory | null) {
		this.categoryQueryParameter = value;
		this.skipQueryParameter = 0;
		this.fetchCourseTiles(true);
	}

	private fetchCourseTilesDebounced = debounce(() => this.fetchCourseTiles(true), 1000, {trailing: true});

	@action
	setSearchTermQueryParameter(value: string) {
		this.skipQueryParameter = 0;
		this.fetchCourseTilesDebounced();
	}

	@action
	loadMoreCourses() {
		this.skipQueryParameter += this.takeQueryParameter;
		this.fetchCourseTiles(false);
	}

	@computed
	get showLoadMoreButton(): boolean {
		return this.skipQueryParameter + this.takeQueryParameter < this.totalCount;
	}

	@computed
	get loadMoreCourseCount(): number {
		return this.totalCount - this.skipQueryParameter - this.takeQueryParameter;
	}

	@action
	onPageUnload() {
		this.topicIdQueryParameter = "";
		this.orderByQueryParameter = OrderByType.Newest;
		this.skipQueryParameter = 0;
		this.courses = [];
	}
}
