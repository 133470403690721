import React from "react";
import {useAppStore} from "./app.store.consumer";
import {ModalRenderer} from "./modal";
import {observer} from "mobx-react";

const ErrorModal: React.FC<{errorMessage: string; reloadCallback: () => void; logoutAndRestartCallback: () => void}> = ({
	errorMessage,
	reloadCallback,
	logoutAndRestartCallback,
}) => {
	return (
		<ModalRenderer isVisible={true}>
			<div className="info-modal-content">
				<div className="info-modal-header paint-lgrey">
					<h2 className="info-modal-title ink-dgrey ciht-mb-1">System notification</h2>
				</div>
				<div className="info-modal-border" />
				<div className="info-modal-body">
					<div>
						There was a network communication error or another issue on this screen. The support team has been notified and the issue will be
						reviewed shortly.
					</div>
					<div className="vspace20" />

					{errorMessage && (
						<>
							<textarea className="form-fit paint-lgrey" defaultValue={errorMessage} rows={4} disabled />
							<div className="vspace10" />
						</>
					)}
					<div className="info-modal-buttons buttonRow">
						<button onClick={logoutAndRestartCallback} className="btn-base btn-primary  btn-fit-mobile  anim-btn-hover">
							Restart app
						</button>
						<div className="hspace15" />
						<button onClick={reloadCallback} className="btn-base btn-secondary  btn-fit-mobile  anim-btn-hover">
							Reload page
						</button>
					</div>
				</div>
			</div>
		</ModalRenderer>
	);
};

export const Error = observer(() => {
	const appStore = useAppStore();

	return appStore.errorVisible ? (
		<ErrorModal
			errorMessage={appStore.errorMessage}
			reloadCallback={() => window.location.reload()}
			logoutAndRestartCallback={() => appStore.userStore.logout()}
		/>
	) : null;
});
