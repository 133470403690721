export interface AuthConfig {
	token: string;
	refreshToken: string;
	setAdditionalOptions?: (options: RequestInit) => void;
}

export interface WebApiErrorResponse {
	url: string;
	statusCode: number;
	body: string;
	isWebApiErrorResponse: boolean;
	stack: string;
	headers: Headers;
}

export function isWebApiErrorResponse(obj: unknown): obj is WebApiErrorResponse {
	const objAsErrorResponse = obj as WebApiErrorResponse;

	return objAsErrorResponse && objAsErrorResponse.isWebApiErrorResponse;
}

export abstract class ClientBase {
	protected constructor(protected configuration: AuthConfig) {}

	protected async transformOptions(options: RequestInit): Promise<RequestInit> {
		const headers = options.headers as Record<string, string>;

		if (this.configuration.token) {
			headers["Authorization"] = `Bearer ${this.configuration.token}`;
		}

		headers["pragma"] = "no-cache";
		headers["cache-control"] = "no-cache";
		headers["X-Api-Version"] = window.apiVersion;

		if (this.configuration.setAdditionalOptions) {
			this.configuration.setAdditionalOptions(options);
		}

		return options;
	}

	protected transformResult(url: string, response: Response, processor: (response: Response) => Promise<any>): Promise<any> {
		return new Promise((resolve, reject) => {
			processor(response)
				.then(r => {
					this.mapDateStringsToDate(r);
					resolve(r);
				})
				.catch(r => {
					const res: WebApiErrorResponse = {
						isWebApiErrorResponse: true,
						url: url,
						body: r.response as string,
						statusCode: response.status,
						stack: r.stack as string,
						headers: response.headers,
					};

					reject(res);
				});
		});
	}

	protected mapDateStringsToDate(obj: any) {
		if (!obj) {
			return;
		}

		if (obj instanceof Array) {
			for (let item of obj) {
				this.mapDateStringsToDate(item);
			}
		}

		if (typeof obj === "object") {
			for (let key in obj) {
				if (obj.hasOwnProperty(key)) {
					if (
						/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/.test(
							obj[key],
						)
					) {
						obj[key] = Date.parse(obj[key]);
					} else {
						this.mapDateStringsToDate(obj[key]);
					}
				}
			}
		}

		if (
			typeof obj === "string" &&
			/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/.test(
				obj,
			)
		) {
			obj = Date.parse(obj);
		}
	}
}
