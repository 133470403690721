import {UserStore} from "./user.store";
import {observable, action, makeObservable, flow} from "mobx";
import addBusinessDays from "date-fns/addBusinessDays";
import {DlpDashboardStore} from "./dlp.dashboard.store";
import {AchievementsStore} from "./achievements.store";
import {CpdType, PromotionDto} from "../common/webapicall";
import {CihtOrgRouteConstants} from "../resources/ciht-org-route-constants";
import {AppStore} from "./app.store";

export enum DebugPanelOperation {
	Logout = 1,
	ToggleRoleFlags,
	ToggleDashboardAchievementsPreview,
	ToggleDashboardCpdProgress,
	ToggleDashboardPromotionalEvent,
	ToggleAchievements,
	ToggleDashboardInProgressCourses,
}

export class DebugPanelStore {
	@observable debugPanelVisible = false;
	@observable isLoading = false;
	@observable operation: DebugPanelOperation | null = null;
	@observable isAchievementsPageEmptied: boolean;

	promotions: PromotionDto[] | null = [];

	constructor(
		private userStore: UserStore,
		private dashboardStore: DlpDashboardStore,
		private achievementsStore: AchievementsStore,
		private appStore: AppStore,
	) {
		makeObservable(this);

		this.isAchievementsPageEmptied = false;
	}

	@action
	toggleTestingPanel = () => {
		this.debugPanelVisible = !this.debugPanelVisible;
	};

	@action
	async logout() {
		await this.userStore.logout();
	}

	@action
	private setLoading(operation: DebugPanelOperation | null) {
		this.isLoading = !!operation;
		this.operation = operation;
	}

	@action
	toggleDashboardAchivements() {
		if (this.dashboardStore.hasAchievements) {
			this.dashboardStore.content.achievementsEarned = 0;
		} else {
			this.dashboardStore.content.achievementsEarned = 1;
		}
	}

	@action
	toggleDashboardInProgressCourses() {
		if (this.dashboardStore.hasInProgressCourses) {
			this.dashboardStore.content.coursesInProgress = 0;
		} else {
			this.dashboardStore.content.coursesInProgress = 1;
		}
	}

	@action
	toggleCpdProgress() {
		this.userStore.cpdProgress.hasCpdProgress = !this.userStore.cpdProgress.hasCpdProgress;
	}

	@action
	toggleDashboardPromotionalEvent() {
		if (this.dashboardStore.hasPromotionalEvent) {
			this.promotions = this.dashboardStore.content.promotions;
			this.dashboardStore.content.promotions = [];
		} else {
			if (!this.promotions || !this.promotions.some(p => p)) {
				this.promotions.push({
					title: "Demo promotion",
					id: "AC9A05BF-EA0C-4B22-9222-739BBDDD0D49",
					startDate: addBusinessDays(new Date(Date.now()), -3),
					imageUrl: process.env.PUBLIC_URL + "/placeholder-small-2.jpg",
					targetUrl: CihtOrgRouteConstants.getCihtEvents(this.appStore.configuration.cihtSiteUrl),
				});
			}

			this.dashboardStore.content.promotions = this.promotions;
		}
	}

	@flow
	*toggleAchievements() {
		if (this.isAchievementsPageEmptied) {
			yield this.achievementsStore.debug_resetAchievements();

			this.isAchievementsPageEmptied = false;
		} else {
			this.achievementsStore.debug_setNoAchievements();

			this.isAchievementsPageEmptied = true;
		}
	}

	@action
	setHasMemberAccess(newValue: boolean) {
		this.userStore.memberInfo.hasDlpMemberAccess = newValue;
	}

	@action
	setShowMembershipExpiringWarningAccess(newValue: boolean) {
		this.userStore.memberInfo.showMembershipExpiringWarning = newValue;
	}

	@action
	setCpdType(newValue: string) {
		this.userStore.memberInfo.cpdType = (newValue as unknown) as CpdType;
	}
}
