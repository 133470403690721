export class RouteConstants {
	static readonly cpd = "/cpd";
	static readonly cpdSwot = "/cpd/swot";
	static readonly cpdDashboard = "/cpd/dashboard";
	static readonly cpdDevelopment = "/cpd/development";
	static readonly cpdCreateGoal = "/cpd/goal/create";
	static readonly cpdCreateActivity = "/cpd/activity/create";
	static readonly cpdEditActivity = "/cpd/activity/edit";
	static readonly cdpEditGoal = "/cpd/goal/edit";

	static readonly dlp = "/dlp";
	static readonly dlpDashboard = "/dlp/dashboard";
	static readonly dlpCourses = "/dlp/courses";
	static readonly dlpCoursesAll = "/dlp/courses/all";
	static readonly dlpCourseDetails = "/dlp/course";
	static readonly dlpAchievements = "/dlp/achievements";
	static readonly enrolmentPage = "/dlp/course/payment-successful";

	static readonly search = "/search";
	static readonly qualification = "/qualification";
	static readonly qualificationDetail = "/qualification/detail";

	static readonly becomeMember = "/become-member";
	static readonly notFound = "/not-found";
	static readonly login = "/login";
	static readonly loginWithRedirectUrl = "/login/:redirectUrl?";
	static readonly sessionExpired = "/session-expired/:redirectUrl?";
	static readonly accessLinkExpired = "/access-link-expired/:redirectUrl?";
	static readonly forcedLogout = "/forced-logout/:redirectUrl?";
	static readonly accessLinkNotFound = "/access-link-not-found/:redirectUrl?";
	static readonly accessDenied = "/access-denied/:redirectUrl?";
}
