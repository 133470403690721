import React from "react";
import {Link} from "react-router-dom";
import {RouteConstants} from "../resources/route-constants";

export function BecomeMember() {
	return (
		<div>
			<h1>Become a member</h1>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, explicabo ipsum iste nemo temporibus totam velit voluptatem. Adipisci
				consequatur, debitis ducimus error illo quidem quisquam rem saepe ut, velit veniam?
			</p>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime minus suscipit tempore. Animi aperiam blanditiis ea et ipsum maxime neque
				soluta! Consequatur eligendi excepturi exercitationem iure modi perferendis quam rem!
			</p>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium amet architecto autem cum delectus dolore eius ex exercitationem expedita
				explicabo id iste maiores optio praesentium quia rerum sed totam, ut!
			</p>
			<Link to={RouteConstants.dlpDashboard}>Home</Link>
		</div>
	);
}
