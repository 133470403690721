import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const MainBlank: React.FC = () => {
	return (
		<div className="ciht-preloader">
			<FontAwesomeIcon icon={["fas", "spinner"]} spin transform="grow-18" />
		</div>
	);
};
