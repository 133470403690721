import {action, computed, flow, makeObservable, observable} from "mobx";
import {IApiClient} from "../common/api.client";
import {CourseBadge} from "../common/webapicall";
import {LoadingState} from "../types/LoadingState";

export class AchievementsStore {
	private readonly apiClient: IApiClient;
	@observable loadingState: LoadingState;

	@observable completedAchievements: CourseBadge[];
	@observable inProgressAchievements: CourseBadge[];

	constructor(apiClient: IApiClient) {
		makeObservable(this);

		this.loadingState = "initial";
		this.apiClient = apiClient;
		this.completedAchievements = [];
		this.inProgressAchievements = [];
	}

	@computed
	get hasAchievements(): boolean {
		return this.loadingState === "completed" && (this.completedAchievements.length > 0 || this.inProgressAchievements.length > 0);
	}

	@computed
	get hasCompletedAchievements(): boolean {
		return this.loadingState === "completed" && this.completedAchievements.length > 0;
	}

	@computed
	get hasInProgressAchievements(): boolean {
		return this.loadingState === "completed" && this.inProgressAchievements.length > 0;
	}

	@flow
	*onPageLoad() {
		this.loadingState = "loading";

		const badges: CourseBadge[] = yield this.apiClient.badgeClient.getBadges();				 

		this.completedAchievements = badges.filter(badge => badge.isCompleted);
		this.inProgressAchievements = badges.filter(badge => !badge.isCompleted);

		this.loadingState = "completed";
	}

	@action
	debug_setNoAchievements() {
		this.completedAchievements = [];
		this.inProgressAchievements = [];
	}

	@flow
	*debug_resetAchievements() {
		yield this.onPageLoad();
	}

	@action
	onPageUnload() {
		this.loadingState = "initial";

		this.completedAchievements = [];
		this.inProgressAchievements = [];
	}
}
