import {action, flow, makeObservable, observable} from "mobx";
import {LoadingState} from "../types/LoadingState";
import {IApiClient} from "../common/api.client";
import {DevelopmentGoalDto} from "../common/webapicall";

export class CpdDevelopmentPlanDashboardStore {
	@observable developmentGoals: DevelopmentGoalDto[];
	@observable loadingState: LoadingState;

	constructor(private apiClient: IApiClient) {
		makeObservable(this);

		this.developmentGoals = [];

		this.loadingState = "initial";
	}

	@flow
	*init() {
		this.loadingState = "loading";

		this.developmentGoals = yield this.apiClient.developmentGoalClient.getAll();

		this.loadingState = "completed";
	}

	@action
	unload() {
		this.loadingState = "initial";
		this.developmentGoals = [];
	}

	async getGoalsExcel() {
		return await this.apiClient.developmentGoalClient.getGoalsExcel();
	}
}
