export class CihtOrgRouteConstants {
	static readonly getEditPreferences = (baseUrl: string) => new URL("/myciht/information/", baseUrl).href;

	static readonly getTermsAndConditions = (baseUrl: string) => new URL("/terms-and-conditions/", baseUrl).href;
	static readonly getPrivacyStatement = (baseUrl: string) => new URL("/privacy-statement/", baseUrl).href;
	static readonly getVacanciesAtCiht = (baseUrl: string) => new URL("/vacancies-at-ciht/", baseUrl).href;
	static readonly getDisclaimer = (baseUrl: string) => new URL("/disclaimer/", baseUrl).href;
	static readonly getContactUs = (baseUrl: string) => new URL("/about-us/about-ciht/contact-us/", baseUrl).href;

	static readonly getLearnMoreAboutCiht = (baseUrl: string) => new URL("/about-us/", baseUrl).href;
	static readonly getCihtEvents = (baseUrl: string) => new URL("/events-listing/", baseUrl).href;
	static readonly getProfessionalDevelopment = (baseUrl: string) => new URL("/professional-development/", baseUrl).href;
	static readonly getKnowledgeAndResources = (baseUrl: string) => new URL("/knowledge-resource-centre/", baseUrl).href;
	static readonly getBecomeAMember = (baseUrl: string) => new URL("/become-a-member/", baseUrl).href;
	static readonly getMembersArea = (baseUrl: string) => new URL("/members-area/", baseUrl).href;
	static readonly getMyAccount = (baseUrl: string) => new URL("/myciht/home/", baseUrl).href;
	static readonly getIndividualMembership = (baseUrl: string) => new URL("/become-a-member/ciht-membership-for-individuals/", baseUrl).href;
	static readonly getAboutPartnership = (baseUrl: string) => new URL("/become-a-member/partnerships-network/", baseUrl).href;
	static readonly getRegister = (baseUrl: string) => new URL("/ciht-learn/register", baseUrl).href;
	static readonly getSearchResults = (baseUrl: string, searchTerm: string) => new URL(`/search-results/?search=${searchTerm}`, baseUrl).href;
}
